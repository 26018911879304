import { Logo } from '../styles/icons';
import { useIntl, Link } from 'gatsby-plugin-react-intl';

export const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <footer tw="text-gray-400 font-body" id="footer">
      <div tw="container pb-5 pt-5 mx-auto flex justify-between md:items-center lg:items-start md:flex-row flex-col">
        <div tw="lg:w-60 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a
            href="/"
            tw="flex font-medium items-center md:justify-start justify-center text-white"
            aria-label="our Logo"
          >
            <Logo width={100} height={70} />
          </a>
          <div tw="flex justify-center mx-auto my-1 mt-4 mb-3 md:(mx-0 mt-2 justify-between) max-width[240px]">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/Bidda-104083848603876"
              target="_blank"
              tw="text-white hover:text-gray-400"
              aria-label="link to our facebook"
              id="click-gtag-footer-fb"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-8 h-8 md:(w-5 h-5)"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/bidda_io"
              target="_blank"
              rel="noreferrer"
              tw="ml-2 md:ml-0 text-white hover:text-gray-400"
              aria-label="link to our twitter"
              id="click-gtag-footer-tw"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-8 h-8 md:(w-5 h-5)"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/bidda_io/"
              target="_blank"
              tw="ml-2 md:ml-0 text-white hover:text-gray-400"
              aria-label="link to our instagram"
              id="click-gtag-footer-ig"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-8 h-8 md:(w-5 h-5)"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              href="https://medium.com/@bidda_io"
              rel="noreferrer"
              target="_blank"
              tw="ml-2 md:ml-0 text-white hover:text-gray-400"
              aria-label="link to our medium"
              id="click-gtag-footer-medium"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 24 24"
                tw="w-8 h-8 md:(w-5 h-5)"
              >
                <path
                  d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/bidda-io/"
              rel="noreferrer"
              target="_blank"
              tw="ml-2 md:ml-0 text-white hover:text-gray-400"
              aria-label="link to our linkedin"
              id="click-gtag-footer-li"
            >
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                tw="w-8 h-8 md:(w-5 h-5)"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
            <a
              href="https://www.reddit.com/user/Bidda_IO"
              rel="noreferrer"
              target="_blank"
              tw="ml-2 md:ml-0 text-white hover:text-gray-400"
              aria-label="link to our reddit"
              id="click-gtag-footer-reddit"
            >
              <svg
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                fill="currentColor"
                viewBox="0 0 24 24"
                tw="w-8 h-8 md:(w-5 h-5)"
              >
                <path d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z" />
              </svg>
            </a>
          </div>
          <div tw="flex flex-col items-center mt-7 md:(items-start mt-5)">
            <a
              href="https://t.me/joinchat/LmT2MYmtZVxkYmI0"
              rel="noreferrer"
              target="_blank"
              tw="flex flex-col items-center mb-2 md:(flex-row-reverse items-start) text-white hover:text-gray-400"
              aria-label="link to our medium"
            >
              Bidda Talks
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.41421"
                xmlns="http://www.w3.org/2000/svg"
                tw="w-8 h-8 mt-2 md:(w-5 h-5 mt-0 mr-2)"
              >
                <path
                  id="telegram-4"
                  d="M12,0c-6.626,0 -12,5.372 -12,12c0,6.627 5.374,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.628 -5.373,-12 -12,-12Zm3.224,17.871c0.188,0.133 0.43,0.166 0.646,0.085c0.215,-0.082 0.374,-0.267 0.422,-0.491c0.507,-2.382 1.737,-8.412 2.198,-10.578c0.035,-0.164 -0.023,-0.334 -0.151,-0.443c-0.129,-0.109 -0.307,-0.14 -0.465,-0.082c-2.446,0.906 -9.979,3.732 -13.058,4.871c-0.195,0.073 -0.322,0.26 -0.316,0.467c0.007,0.206 0.146,0.385 0.346,0.445c1.381,0.413 3.193,0.988 3.193,0.988c0,0 0.847,2.558 1.288,3.858c0.056,0.164 0.184,0.292 0.352,0.336c0.169,0.044 0.348,-0.002 0.474,-0.121c0.709,-0.669 1.805,-1.704 1.805,-1.704c0,0 2.084,1.527 3.266,2.369Zm-6.423,-5.062l0.98,3.231l0.218,-2.046c0,0 3.783,-3.413 5.941,-5.358c0.063,-0.057 0.071,-0.153 0.019,-0.22c-0.052,-0.067 -0.148,-0.083 -0.219,-0.037c-2.5,1.596 -6.939,4.43 -6.939,4.43Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="https://t.me/bidda_nft"
              rel="noreferrer"
              target="_blank"
              tw="flex flex-col items-center mb-2 md:(flex-row-reverse items-start) text-white hover:text-gray-400"
              aria-label="link to our medium"
            >
              Bidda Announcements
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.41421"
                xmlns="http://www.w3.org/2000/svg"
                tw="w-8 h-8 mt-2 md:(w-5 h-5 mt-0 mr-2)"
              >
                <path
                  id="telegram-4"
                  d="M12,0c-6.626,0 -12,5.372 -12,12c0,6.627 5.374,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.628 -5.373,-12 -12,-12Zm3.224,17.871c0.188,0.133 0.43,0.166 0.646,0.085c0.215,-0.082 0.374,-0.267 0.422,-0.491c0.507,-2.382 1.737,-8.412 2.198,-10.578c0.035,-0.164 -0.023,-0.334 -0.151,-0.443c-0.129,-0.109 -0.307,-0.14 -0.465,-0.082c-2.446,0.906 -9.979,3.732 -13.058,4.871c-0.195,0.073 -0.322,0.26 -0.316,0.467c0.007,0.206 0.146,0.385 0.346,0.445c1.381,0.413 3.193,0.988 3.193,0.988c0,0 0.847,2.558 1.288,3.858c0.056,0.164 0.184,0.292 0.352,0.336c0.169,0.044 0.348,-0.002 0.474,-0.121c0.709,-0.669 1.805,-1.704 1.805,-1.704c0,0 2.084,1.527 3.266,2.369Zm-6.423,-5.062l0.98,3.231l0.218,-2.046c0,0 3.783,-3.413 5.941,-5.358c0.063,-0.057 0.071,-0.153 0.019,-0.22c-0.052,-0.067 -0.148,-0.083 -0.219,-0.037c-2.5,1.596 -6.939,4.43 -6.939,4.43Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <p tw="text-sm text-white leading-6">
            © 2021 Bidda sp. z o.o.
            <br />
            <span tw="text-gray-400">Olszańska 7, 31-513 Cracow, Poland</span>
          </p>
        </div>
        <div tw="flex flex-col md:(flex-row text-left self-end mt-0 text-sm) lg:(w-3/4 text-base) self-center mt-10 text-center">
          <div tw="lg:w-1/3 md:w-1/3 w-full px-4 flex justify-center">
            <ul tw="list-none">
              <li tw="pt-2 md:pt-4">
                <p
                  tw="text-gray-400 hover:text-white"
                  className="pointer"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {formatMessage({ id: 'footer.home' })}
                </p>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/business/#how-it-works"
                  tw="text-gray-400 hover:text-white"
                >
                  {formatMessage({ id: 'footer.howItWorks' })}
                </Link>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link to="/#whitepaper" tw="text-gray-400 hover:text-white">
                  {formatMessage({ id: 'footer.whitepaper' })}
                </Link>
              </li>
            </ul>
          </div>
          <div tw="lg:w-1/3 md:w-1/3 w-full px-4 flex justify-center">
            <ul tw="list-none">
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/"
                  tw="text-gray-400 hover:text-white"
                  id="click-gtag-footer-token-sale"
                >
                  {formatMessage({ id: 'footer.tokenSale' })}
                </Link>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link to="/business/#faq" tw="text-gray-400 hover:text-white">
                  {formatMessage({ id: 'footer.faq' })}
                </Link>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/legal/general-risk"
                  tw="text-gray-400 hover:text-white"
                >
                  {formatMessage({ id: 'footer.generalRisk' })}
                </Link>
              </li>
            </ul>
          </div>
          <nav tw="lg:w-1/3 md:w-1/3 w-full px-4 flex justify-center">
            <ul tw="list-none">
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/legal/privacy-policy"
                  tw="text-gray-400 hover:text-white"
                >
                  {formatMessage({ id: 'footer.privacyPolicy' })}
                </Link>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/legal/cookies-policy"
                  tw="text-gray-400 hover:text-white"
                >
                  {formatMessage({ id: 'footer.cookies' })}
                </Link>
              </li>
              <li tw="pt-2 md:pt-4">
                <Link
                  to="/legal/terms-conditions"
                  tw="text-gray-400 hover:text-white"
                >
                  {formatMessage({ id: 'footer.termsOfUse' })}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};
